import { ReactNode, FC, useState, useEffect } from 'react'

import { MarketModelByZipCode } from 'api/nest'
import { Button } from 'components/button'
import { ContentHeader } from 'components/search-markets-by-zip-code/content-header'
import { Heading } from 'components/heading'
import { Form, useForm } from 'components/form'
import { MaskedTextFormField } from 'components/form-field/TextFormField/MaskedTextFormField'

import classes from './price-calculations.module.css'
import {
  CUSTOM_AMOUNT_SCHEMA,
  FIELD,
  handlePresetClick,
  presets,
  convertToNumber,
  validatePayoutInput,
  getPricingData,
  getCustomPayout,
} from './utils'

interface IPriceCalculationsProps {
  market: MarketModelByZipCode
  zipCode: string
  openCalculationsModal: () => void
}

const description_text =
  'Great news! Home Price Protection is available in your area. The zip code you entered'

const getDescription = (marketName: string, zipCode: string): ReactNode => (
  <>
    <Heading theme="h4" component="h5" className={classes.title}>
      You&apos;re in Luck! Home Price Protection is Here for You!
    </Heading>
    {description_text} <strong className={classes.accent}>{zipCode}</strong> falls within the{' '}
    <strong className={classes.accent}>{marketName.split(',')[0]}</strong> Market.
  </>
)

export const PriceCalculations: FC<IPriceCalculationsProps> = ({
  market,
  zipCode,
  openCalculationsModal,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [calculateBtnState, setCalculateBtnState] = useState(true)

  const minProtectionValue = market.defaultMaxPayout || 0
  const marketName = market.name || ''
  const customPayout = getCustomPayout()

  const formMethods = useForm(CUSTOM_AMOUNT_SCHEMA, {
    defaultValues: {
      [FIELD.amount]: customPayout ? customPayout : String(minProtectionValue),
    },
  })

  const amount = formMethods.watch(FIELD.amount)

  useEffect(() => {
    validatePayoutInput(amount, formMethods, minProtectionValue, marketName, setCalculateBtnState)
  }, [amount, formMethods, minProtectionValue, marketName, setCalculateBtnState])

  const handleCalculation = async (amountVal: string, uuid?: string) => {
    setLoading(true)

    const pricingData = await getPricingData(amountVal, uuid)
    const amountNumber = convertToNumber(amount)
    sessionStorage.setItem(
      'pricingCalculation',
      JSON.stringify({ pricingData, market, zipCode, protectionAmount: amountNumber }),
    )
    openCalculationsModal()
  }

  return (
    <div>
      <ContentHeader text={getDescription(market.name, zipCode)} className={classes.header} />
      <Heading theme="h5" component="h5" className={classes.title}>
        To see pricing, choose a protection amount
      </Heading>
      <p className={classes.details}>
        Enter a maximum payout value or choose from preset values and click &apos;CALCULATE&apos; to
        continue.
      </p>
      <div className={classes.sectionsWrapper}>
        <div className={classes.leftSection}>
          <Heading theme="h5" className={classes.sectionTitle}>
            ENTER AN AMOUNT
          </Heading>

          <div>
            <Heading theme="h5" className={classes.sectionDetails}>
              Type a custom amount
            </Heading>
            <Form
              formMethods={formMethods}
              onSubmit={() => handleCalculation(amount, market.defaultOffering?.uuid)}
            >
              <MaskedTextFormField
                name="amount"
                placeholder="$"
                mask="$ num"
                blocks={{
                  num: {
                    mask: Number,
                    thousandsSeparator: ',',
                  },
                }}
                className={classes.payoutInput}
              />
              <Button type="submit" disabled={calculateBtnState}>
                {isLoading ? 'CALCULATING...' : 'CALCULATE'}
              </Button>
            </Form>
          </div>
        </div>
        <div className={classes.rightSection}>
          <Heading theme="h5" className={classes.sectionTitle}>
            SELECT A PRESET
          </Heading>
          <div className={classes.presetsWrapper}>
            {presets.map((p) => (
              <Button theme="secondary" onClick={() => handlePresetClick(p, formMethods)} key={p}>
                {p}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
